<template>
  <div>
    <v-data-table
      hide-default-footer
      :headers="header"
      :loading="loading"
      :items="orderPromotions"
      :server-items-length="100"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateUserFormat }}
      </template>
      <template v-slot:[`item.orderId`]="{ item }">
        <a @click="viewOrder(item.orderId)">{{ item.orderId }}</a>
      </template>
      <template v-slot:[`item.channel`]="{ item }">
        {{ item.channel.toUpperCase() }}
      </template>
      <template v-slot:[`item.net`]="{ item }">
        {{ item.net | showFullPriceFormat }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        {{ item.discount | showFullPriceFormat }}
      </template>
      <template v-slot:[`item.currentState`]="{ item }">
        {{ mapStateHistory(item.currentState).toUpperCase() }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
    props: {
      orderPromotions: {
        type: Array,
        default: () => ([])
      },
      loading: {
        type: Boolean,
        default: false
      },
      totalItem: {
        type: Number,
        default: 0
      }
    },
    data () {
        return {
             header: [
                {
                    text: 'วันที่ซื้อ',
                    value: 'createdAt',
                    width: 100
                },
                {
                    text: 'Order ID',
                    value: 'orderId',
                    width: 100,
                    align: 'center'
                },
                {
                    text: 'net (บาท)',
                    align: 'right',
                    value: 'net',
                    width: 200
                },
                {
                    text: 'ส่วนลด (บาท)',
                    align: 'right',
                    value: 'discount',
                    width: 150
                },
                {
                    text: 'เบอร์โทรศัพท์',
                    value: 'tel',
                    width: 150
                },
                {
                    text: 'ช่องทาง',
                    value: 'channel',
                    width: 150
                },
                {
                    text: 'สถานะ',
                    value: 'currentState',
                    width: 150
                }
            ]
        }
    },
    computed: {
    },
    methods: {
      openNewTab (routeInfo) {
          const routeData = this.$router.resolve({ name: routeInfo.name, query: routeInfo.query })
          window.open(routeData.href, '_blank')
      },
      viewOrder (orderId) {
          this.openNewTab({ name: 'OrderList', query: { brand: 'all', channel: 'all', search: orderId } })
      },
      mapStateHistory (state) {
        switch (state) {
          case 'prepare_create': return 'Prepare Create'
          case 'add_tracking_no': return 'Add Tracking No'
          case 'update_order': return 'Update Order'
          case 'pending': return 'Pending'
          case 'reserved': return 'Reserved'
          case 'waiting': return 'Waiting'
          case 'update_shipping': return 'Update Shipping'
          case 'paid': return 'Paid'
          case 'closed': return 'Closed'
          case 'ready_to_ship': return 'Ready to ship'
          case 'deduct_stock': return 'Deduct Stock'
          case 'completed': return 'Completed'
          case 'kick': return 'Kick'
          default: return state
        }
      }
    }
}
</script>
