<template>
  <v-container
    class="products-info-page"
    fluid>
    <v-row>
      <v-col
        cols="4">
        <v-card>
          <v-card-title class="subheading font-weight-bold">
            <v-btn
              icon
              @click="$router.push({ name: 'PromotionManagement' })">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            รายละเอียดโค้ดส่วนลด
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="editClicked">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <v-btn
              color="red"
              icon
              @click="deleteClicked">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <promotion-info-table
            v-if="promotionInfo"
            :total-used="totalItem"
            :promotion-info="promotionInfo"></promotion-info-table>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card class="pb-4">
          <v-card-title class="subheading font-weight-bold">
            ออเดอร์ที่ใช้โค้ดส่วนลด ({{ totalItem }})
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              @click="exportCsv">
              EXPORT CSV
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <PromotionOrderTable
            :total-item="totalItem"
            :order-promotions="orderPromotions"
            :loading="loadingOrder" />
          <v-pagination
            v-model="query.page"
            class="mt-4"
            :loading="loadingOrder"
            :length="totalPage"
            :total-visible="7"
            :disabled="loadingOrder"
            @input="getOrderPromotion" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionProvider from '@/resources/PromotionProvider'
import OrderProvider from '@/resources/OrderProvider'
import NewReportProvider from '@/resources/NewReportProvider'
import PromotionInfoTable from '../components/PromotionInfoTable.vue'
import PromotionOrderTable from '../components/PromotionOrderTable.vue'

const PromotionService = new PromotionProvider()
const OrderService = new OrderProvider()
const NewReportService = new NewReportProvider()

export default {
    components: {
        PromotionInfoTable,
        PromotionOrderTable
    },
    data () {
        return {
            promotionInfo: null,
            orderPromotions: [],
            loading: true,
            loadingOrder: false,
            totalItem: 0,
            totalPage: 0,
            query: {
                limit: 10,
                page: 1,
                channel: 'all',
                code: this.$route.params.promotionCode
            }
        }
    },
    mounted () {
        this.getPromotionInfoByCode()
        this.getOrderPromotion()
    },
    methods: {
        ...mapActions({
            setSnackbar: 'Components/setSnackbar',
            setModal: 'Components/setModal'
        }),
        async getPromotionInfoByCode () {
            try {
                this.loading = true
                const { data } = await PromotionService.getPromotionInfoById(this.$route.params.promotionId)
                this.promotionInfo = data
            } catch (error) {
                this.setSnackbar({
                value: true,
                message: `[GETTING PROMOTIONS ERROR]: ${error.message}`,
                type: 'error'
                })
            } finally {
                this.loading = false
            }
        },
        async getOrderPromotion () {
            try {
                this.loadingOrder = true
                const { data } = await OrderService.getOrderPromotion(this.$route.params.promotionId, this.query)
                this.orderPromotions = data.results
                this.totalItem = data.total
                this.totalPage = data.pages
            } catch (error) {
                this.setSnackbar({
                value: true,
                message: `[GETTING PROMOTIONS ORDER ERROR]: ${error.message}`,
                type: 'error'
                })
            } finally {
                this.loadingOrder = false
            }
        },
        async exportCsv () {
            try {
                this.loadingOrder = true
                const { data } = await NewReportService.getOrderPromotionReport(this.$route.params.promotionId, { code: this.query.code })
                this.download(data)
            } catch (error) {
                this.setSnackbar({
                value: true,
                message: `[GETTING PROMOTIONS INFO ERROR]: ${error.message}`,
                type: 'error'
                })
            } finally {
                this.loadingOrder = false
            }
        },
        download (url, fileName) {
            let tmpFilename = fileName

            if (!fileName) {
                tmpFilename = this.$dayjs().format('YYYYMMDDHHmmss')
            }
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = tmpFilename
            downloadLink.click()
        },
        editClicked () {
          this.$router.push({ name: 'EditPromotion', params: { id: this.$route.params.promotionId } })
        },
        async deleteClicked () {
          this.setModal({
            value: true,
            title: 'Delete promotion',
            message: 'Do you want to delete this promotion?',
            confirmText: 'Confirm',
            confirmType: 'error',
            cancelType: '',
            cancelText: 'Cancel',
            onConfirm: async () => {
              await this.removePromotion(this.$route.params.promotionId)
            }
          })
        },
        async removePromotion (promotionId) {
          try {
            await PromotionService.removePromotion(promotionId)
            this.setSnackbar({
              value: true,
              message: 'ลบรหัสโปรโมชั่นสำเร็จ',
              type: 'success'
            })
            this.$router.push({ name: 'PromotionManagement' })
          } catch (error) {
            this.setSnackbar({
              value: true,
              message: `[DELETE PROMOTIONS ERROR]: ${error.message}`,
              type: 'error'
            })
          }
        }
    }
}
</script>
